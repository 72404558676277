<template>
  <v-row class="device-info-panel">
    <v-col cols="12">
      <v-card>
        <v-card-title class="justify-center">
          <div class="text-center">
            Device ID:
            <v-chip label small>
              {{ device.device_id }}
            </v-chip>
          </div>
        </v-card-title>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">Details</h2>
          <v-divider class="mt-2 mb-2"></v-divider>

          <v-list-item dense class="px-0">
            <v-row>
              <v-col class="d-flex" cols="5">
                <span class="font-weight-medium me-2">Vehicle ID:</span>
              </v-col>
              <v-col cols="7">
                <router-link
                  v-if="device.config.vehicle_id"
                  :to="{ name: 'next-stop-vehicle-config', params: { id: device.config.vehicle_id } }"
                  class="cursor-pointer text-decoration-none"
                >
                  {{ device.config.vehicle_id }}
                </router-link>
                <span v-else class="text--secondary">Unknown</span>
              </v-col>
            </v-row>
          </v-list-item>

          <v-list>
            <v-list-item dense class="px-0">
              <v-row>
                <v-col class="d-flex" cols="5">
                  <span class="font-weight-medium me-2">Status:</span>
                </v-col>
                <v-col cols="7">
                  <span class="text--secondary">
                    {{ getFormattedDeviceStatus(device) }}
                  </span>
                </v-col>
              </v-row>
            </v-list-item>

            <v-list-item dense class="px-0">
              <v-row>
                <v-col class="d-flex" cols="5">
                  <span class="font-weight-medium me-2">SSD ID:</span>
                </v-col>
                <v-col cols="7">
                  <span class="text--secondary">
                    {{ device.config.ssd_serial ? device.config.ssd_serial : "Unknown" }}
                  </span>
                </v-col>
              </v-row>
            </v-list-item>

            <v-list-item dense class="px-0">
              <v-row>
                <v-col class="d-flex" cols="5">
                  <span class="font-weight-medium me-2">Hardware ID:</span>
                </v-col>
                <v-col cols="7">
                  <span class="text--secondary">
                    {{ device.config.case_id ? device.config.case_id : "Unknown" }}
                  </span>
                </v-col>
              </v-row>
            </v-list-item>

            <v-list-item dense class="px-0">
              <v-row>
                <v-col class="d-flex" cols="5">
                  <span class="font-weight-medium me-2">Release Version:</span>
                </v-col>
                <v-col cols="7">
                  <span class="text--secondary">
                    {{ device.status.release_version ? device.status.release_version : "Unknown" }}
                  </span>
                </v-col>
              </v-row>
            </v-list-item>

            <v-list-item dense class="px-0">
              <v-row>
                <v-col class="d-flex" cols="5">
                  <span class="font-weight-medium me-2">Target Release Version:</span>
                </v-col>
                <v-col cols="7">
                  <span class="text--secondary">
                    {{ device.status.target_release_version ? device.status.target_release_version : "Unknown" }}
                  </span>
                </v-col>
              </v-row>
            </v-list-item>

            <v-list-item dense class="px-0">
              <v-row>
                <v-col class="d-flex" cols="5">
                  <span class="font-weight-medium me-2">Last Updated:</span>
                </v-col>
                <v-col cols="7">
                  <span class="text--secondary">
                    {{
                      device.status.device_timestamp ? formatDate(device.status.device_timestamp, "en-NZ") : "Unknown"
                    }}
                  </span>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex"
import "mapbox-gl/dist/mapbox-gl.css"
import globalHelpers from "@/mixins/globalHelpers"

export default {
  components: {},
  mixins: [globalHelpers],

  computed: {
    ...mapState({
      device: state => state.selected_device,
    }),
  },

  methods: {
    // Determines if the device is effectively offline or online
    deviceOfflineOnlineStatus(detailedStatus) {
      return detailedStatus === "idle" || detailedStatus === "updating" ? "online" : "offline"
    },

    // Returns the device offline/online status and a detailed status if available
    getFormattedDeviceStatus(device) {
      const detailedStatus = device.status.status || "Unknown"
      const overallStatus = this.deviceOfflineOnlineStatus(detailedStatus)

      if (detailedStatus !== overallStatus) {
        return `${overallStatus} (${detailedStatus})`
      }

      return overallStatus
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/next-stop/components/map.scss";
</style>
