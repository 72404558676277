<template>
  <v-row class="route-info-panel">
    <v-col cols="12">
      <v-card>
        <v-card-title class="justify-center flex-column">
          <v-row class="w-full">
            <v-col cols="12" class="text-sm text-center font-weight-bold">
              <span class="text-capitalize font-weight-semibold text--primary">
                <v-tooltip bottom class="text--primary">
                  <template #activator="{ on, attrs }">
                    <v-icon size="38" v-bind="attrs" color="primary" v-on="on">
                      {{ getRouteTypePresentation(route.route_type).icon }}
                    </v-icon>
                  </template>
                  <span>{{ getRouteTypePresentation(route.route_type).text }}</span>
                </v-tooltip>
              </span>
              <badge :route="route" :size="34"></badge>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">Details</h2>
          <v-divider class="mt-2 mb-2"></v-divider>
          <v-list>
            <v-list-item dense class="px-0">
              <v-row>
                <v-col class="d-flex" cols="4">
                  <span class="font-weight-medium me-2"> ID:</span>
                </v-col>
                <v-col cols="8">
                  <span class="text--secondary">{{ route.route_id }}</span>
                </v-col>
              </v-row>
            </v-list-item>
            <v-list-item dense class="px-0">
              <v-row>
                <v-col class="d-flex" cols="4">
                  <span class="font-weight-medium me-2"> Code:</span>
                </v-col>
                <v-col cols="8">
                  <span class="text--secondary">{{ route.route_short_name }}</span>
                </v-col>
              </v-row>
            </v-list-item>
            <v-list-item dense class="px-0">
              <v-row>
                <v-col class="d-flex" cols="4">
                  <span class="font-weight-medium me-2"> Name:</span>
                </v-col>
                <v-col cols="8">
                  <span class="text--secondary">{{ route.route_long_name }}</span>
                </v-col>
              </v-row>
            </v-list-item>
            <v-list-item dense class="px-0">
              <v-row>
                <v-col class="d-flex" cols="4">
                  <span class="font-weight-medium me-2"> Description:</span>
                </v-col>
                <v-col cols="8">
                  <span class="text--secondary">{{ route.route_desc }}</span>
                </v-col>
              </v-row>
            </v-list-item>
            <v-list-item dense class="px-0">
              <v-row>
                <v-col class="d-flex" cols="4">
                  <span class="font-weight-medium me-2"> Mode:</span>
                </v-col>
                <v-col cols="8">
                  <span class="text--secondary">{{ routeTypePresentation(route.route_type).text }}</span>
                </v-col>
              </v-row>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
      <v-card class="mt-5">
        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">Stops</h2>
          <v-divider class="mt-2"></v-divider>
          <v-data-table
            :headers="routeStopHeaders"
            :items="relatedStops"
            item-key="stop.stop_id"
            hide-default-footer
            hide-default-header
            disable-pagination
            dense
          >
            <template #[`item.stop_name`]="{ item }">
              <router-link
                class="router-link-stop"
                :to="{ name: 'next-stop-stop-config', params: { id: item.stop_id } }"
              >
                <span class="font-weight-semibold text--primary">
                  {{ item.stop_id }} - {{ item.stop_name }}
                </span>
              </router-link>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiArrowLeft, mdiRoutes, mdiOpenInNew } from "@mdi/js"
import { mapState } from "vuex"
import Badge from "@/components/route/Badge.vue"
import globalHelpers from "@/mixins/globalHelpers"

export default {
  name: "RouteInfoPanel",
  components: {
    Badge,
  },
  mixins: [globalHelpers],
  data() {
    return {
      routeStopHeaders: [{ text: "Description", value: "stop_name", sortable: false }],
    }
  },
  computed: {
    ...mapState({
      route: state => state.selected_route,
      stops: state => state.gtfs.stops,
    }),
    relatedStops() {
      return this.stops.filter(stop => this.route.stops.includes(stop.stop_id))
    },
  },
  methods: {
    getRouteTypePresentation(routeType) {
      return this.routeTypePresentation(routeType)
    },
  },
  setup() {
    return {
      icons: {
        mdiArrowLeft,
        mdiRoutes,
        mdiOpenInNew,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.v-expansion-panel:before {
  box-shadow: none !important;
}

.router-link-stop {
  text-decoration: none;
}
</style>
