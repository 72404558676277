<template>
  <div id="dashboard">
    <div class="page-title px-4">
      <h2>
        <span class="me-2">{{ appName }} Dashboard</span>
      </h2>
    </div>

    <v-divider class="mt-4 mb-10"></v-divider>

    <v-row>
      <v-col cols="12" md="6">
        <v-card class="greeting-card pb-2">
          <v-row class="ma-0 pa-0">
            <v-col v-if="$auth.isAuthenticated" cols="12">
              <v-card-title class="text-no-wrap pt-1 ps-2">
                <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="mr-4" dot>
                  <v-avatar size="40px" color="primary" class="v-avatar-light-bg primary--text">
                    <v-img :src="$auth.avatar"></v-img>
                  </v-avatar>
                </v-badge>
                Welcome back, {{ $auth.user.name }}
              </v-card-title>
              <v-card-text class="text-left mt-2 pb-2 ps-2">
                <div>
                  <p class="mb-2">Your profile:</p>
                  <p v-if="$auth.user.name" class="font-weight-semibold primary--text mb-2">
                    <v-icon color="primary" size="18">
                      {{ icons.mdiAccountOutline }}
                    </v-icon>
                    Name: {{ $auth.user.name }}
                  </p>
                  <p class="font-weight-semibold primary--text mb-2">
                    <v-icon color="primary" size="18">
                      {{ icons.mdiEmailBox }}
                    </v-icon>
                    Email: {{ $auth.user.email }}
                  </p>
                </div>
              </v-card-text>

              <v-card-text align="end" class="pb-1">
                <v-btn small color="primary" append-icon="ri-logout-box-r-line" @click="logout">
                  <v-icon :size="16" class="me-2">
                    {{ icons.mdiLogoutVariant }}
                  </v-icon>
                  Logout
                </v-btn>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12" md="6">
            <v-card class="">
              <v-row class="ma-0 pa-0">
                <v-col cols="12">
                  <p class="text-center text-no-wrap pt-1 text--xs">Total Devices</p>
                  <v-card-text class="text-center pb-2 ps-2 text-xl font-weight-semibold text--primary">
                    <div>
                      {{ allDevices.length }}
                    </div>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card class="">
              <v-row class="ma-0 pa-0">
                <v-col cols="12">
                  <p class="text-center text-no-wrap pt-1 text--xs">Enabled Vehicles</p>
                  <v-card-text class="text-center pb-2 ps-2 text-xl font-weight-semibold text--primary">
                    <div>
                      {{ enabledVehicles.length }} / {{ validVehicles.length }}
                    </div>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-card class="">
              <v-row class="ma-0 pa-0">
                <v-col cols="12">
                  <p class="text-center text-no-wrap pt-1 text--xs">Vehicles On Trip</p>
                  <v-card-text class="text-center pb-2 ps-2 text-xl font-weight-semibold text--primary">
                    <div>
                      {{ onTripVehicles.length }}
                    </div>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card class="">
              <v-row class="ma-0 pa-0">
                <v-col cols="12">
                  <p class="text-center text-no-wrap pt-1 text--xs">Open Notes</p>
                  <v-card-text class="text-center pb-2 ps-2 text-xl font-weight-semibold text--primary">
                    <div>
                      {{ openNotes.length }}
                    </div>
                  </v-card-text>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>

      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="3">
        <v-card class="">
          <v-row class="ma-0 pa-0">
            <v-col cols="12">
              <p class="text-center text-no-wrap pt-1 text--xs">Vehicles per Operator</p>

              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Operator</th>
                      <th class="text-left">Vehicles</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="[operator, vehicles] in Object.entries(vehiclesByOperator)" :key="operator">
                      <td>{{ operator }}</td>
                      <td>{{ vehicles.length }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" md="3">
        <v-card class="">
          <v-row class="ma-0 pa-0">
            <v-col cols="12">
              <p class="text-center text-no-wrap pt-1 text--xs">OS Versions (Vehicles)</p>

              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">OS Version</th>
                      <th class="text-left">Vehicles</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="[osVersion, devices] in Object.entries(vehiclesByOSVersion)" :key="osVersion">
                      <td>{{ osVersion }}</td>
                      <td>{{ devices.length }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" md="3">
        <v-card class="">
          <v-row class="ma-0 pa-0">
            <v-col cols="12">
              <p class="text-center text-no-wrap pt-1 text--xs">Software Versions (Vehicles)</p>

              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Software</th>
                      <th class="text-left">Vehicles</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="[releaseVersion, devices] in Object.entries(vehiclesByReleaseVersion)"
                      :key="releaseVersion"
                    >
                      <td>{{ releaseVersion }}</td>
                      <td>{{ devices.length }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" md="3">
        <v-card class="">
          <v-row class="ma-0 pa-0">
            <v-col cols="12">
              <p class="text-center text-no-wrap pt-1 text--xs">Vehicles by Status</p>

              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Status</th>
                      <th class="text-left">Vehicles</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="[status, vehicles] in Object.entries(vehiclesByStatus)" :key="status">
                      <td>{{ status }}</td>
                      <td>{{ vehicles.length }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiLogoutVariant, mdiViewDashboard, mdiAccountOutline, mdiEmailBox, mdiAccountCowboyHat } from "@mdi/js"
import themeConfig from "@themeConfig"
import { mapState } from "vuex"

export default {
  metaInfo() {
    return {
      title: "Dashboard",
    }
  },

  setup() {
    return {
      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiLogoutVariant,
        mdiViewDashboard,
        mdiAccountOutline,
        mdiEmailBox,
        mdiAccountCowboyHat,
      },
    }
  },

  computed: {
    ...mapState({
      vehicles: state => state.gtfs.vehicles,
      devices: state => state.gtfs.devices,
      notes: state => state.gtfs.notes,
    }),
    allDevices() {
      return this.devices
    },
    openNotes() {
      return this.notes.filter(notice => notice.status === "Open")
    },
    enabledVehicles() {
      return this.vehicles.filter(vehicle => vehicle.config.is_enabled === true && vehicle.config.operator !== "Test")
    },
    validVehicles() {
      return this.vehicles.filter(vehicle => vehicle.config.operator !== "Test")
    },
    onTripVehicles() {
      return this.vehicles.filter(vehicle => vehicle.config.is_enabled && vehicle.status.trip.trip_id !== null)
    },
    vehiclesByOperator() {
      return this.vehicles.reduce((acc, vehicle) => {
        const operator = vehicle.config.operator || "-"
        if (!acc[operator]) {
          acc[operator] = []
        }
        acc[operator].push(vehicle)

        return acc
      }, {})
    },
    vehiclesByOSVersion() {
      return this.devices.reduce((acc, device) => {
        // Exclude devices without an assigned vehicle_id
        if (!device.config.vehicle_id) {
          return acc
        }
        const osVersion = device.status.os_version || "Unknown"
        if (!acc[osVersion]) {
          acc[osVersion] = []
        }
        acc[osVersion].push(device)

        return acc
      }, {})
    },
    vehiclesByReleaseVersion() {
      return this.devices.reduce((acc, device) => {
        // Exclude devices without an assigned vehicle_id
        if (!device.config.vehicle_id) {
          return acc
        }
        const releaseVersion = device.status.release_version || "Unknown"
        if (!acc[releaseVersion]) {
          acc[releaseVersion] = []
        }
        acc[releaseVersion].push(device)

        return acc
      }, {})
    },
    vehiclesByStatus() {
      return this.vehicles.reduce((acc, vehicle) => {
        const status = vehicle.status.status || "Unknown"
        if (!acc[status]) {
          acc[status] = []
        }
        acc[status].push(vehicle)

        return acc
      }, {})
    },
  },

  methods: {
    // Log the user out
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      })
    },
  },
}
</script>
